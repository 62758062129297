@import url('https://fonts.googleapis.com/css?family=Dosis');

.quote-wrapper {
    width: 80% !important;
    margin: auto !important;
}
.about-us-quote {
    font-size: 25px !important;
    color: #002868 !important;
}
.quote-wrapper-wrap {
    width: 100%;
    background-color: rgb(233, 241, 255);
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

@media (max-width: 480px) {
    .about-us-quote {
        font-size: 20px !important;
        color: #002868 !important;
    }
    .about-us-quote-icon {
        font-size: 15px !important;
    }
}