.tablinks {
    color: white;
}
.MuiTabs-indicator {
    display: none !important;
}
.active-button {
    border-bottom: 2px solid white !important;
}

.menu {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
    padding: 20px;
    max-width: 800px;
    margin: 20px 55px 20px auto;
    
    @media (max-width: 650px) {
      flex-direction: column;
      align-items: center;
    }
    
    .item {
      padding: 18px 28px;
      cursor: pointer;
      transition: color .3s ease-out;
      text-decoration: none;
      color: #ffff;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      font-size: 15px;
      margin: 5px 0;
      
      &.active {
        color: #fff;
      }
    }
    
    .indicator {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 30px;
    }
  }

  .get-a-quote{
    text-transform: capitalize;
    width: 11rem;
    justify-content: space-evenly;
    right: 50px;
    border: 2px solid white;
    border-radius: 4px;
  }