:root {
    /* Colors */
    --brand-color: #002868;
    --black: hsl(0, 0%, 0%);
    --white: hsl(0, 0%, 100%);
    /* Fonts */
    --font-title: "Montserrat", sans-serif;
    --font-text: "Lato", sans-serif;
}

/* h2 {
    font-size: 2.25rem;
    font-family: var(--font-title);
    color: var(--white);
    line-height: 1.1;
  }
  
  p {
    font-family: var(--font-text);
    font-size: 1rem;
    line-height: 1.5;
    color: var(--white);
  }
  
  .flow > * + * {
    margin-top: var(--flow-space, 1em);
  }
   */
  /* CARD COMPONENT */
  
  .card {
    display: grid;
    place-items: center;
    width: 95%;
    margin: auto !important;
    margin-bottom: 30px !important;
    max-width: 100%;
    height: 28.125rem;
    overflow: hidden;
    border-radius: 0.625rem;
    box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  }
  
  .card > * {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  
  .card__background {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }
  
  .card__content {
    --flow-space: 0.9375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-end;
    height: 55%;
    padding: 12% 1.25rem 1.875rem;
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.3) 10%,
      hsl(0, 0%, 0%) 100%
    );
  }
  
  .card__content--container {
    --flow-space: 1.25rem;
  }
  
  .card__title {
    position: relative;
    width: fit-content;
    width: -moz-fit-content; /* Prefijo necesario para Firefox  */
  }
  
  .card__title::after {
    content: "";
    position: absolute;
    height: 0.3125rem;
    width: calc(100% + 1.25rem);
    bottom: calc((1.25rem - 0.5rem) * -1);
    left: -1.25rem;
    background-color: var(--brand-color);
  }
  
  .card__button {
    padding: 0.75em 1.6em;
    width: fit-content;
    width: -moz-fit-content; /* Prefijo necesario para Firefox  */
    font-variant: small-caps;
    font-weight: bold;
    border-radius: 0.45em;
    border: none;
    background-color: var(--brand-color);
    font-family: var(--font-title);
    font-size: 1.125rem;
    color: var(--white);
  }
  
  .card__button:focus {
    outline: 2px solid black;
    outline-offset: -5px;
  }
  
  @media only screen and (any-pointer: fine) {
    .card__content {
      transform: translateY(62%);
      transition: transform 500ms ease-out;
      transition-delay: 500ms;
    }
  
    .card__title::after {
      opacity: 0;
      transform: scaleX(0);
      transition: opacity 500ms ease-in, transform 500ms ease-out;
      transition-delay: 500ms;
      transform-origin: right;
    }
  
    .card__background {
      transition: transform 500ms ease-in;
    }
  
    .card__content--container > :not(.card__title),
    .card__button {
      opacity: 0;
      transition: transform 500ms ease-out, opacity 500ms ease-out;
    }
  
    .card:hover,
    .card:focus-within {
      transform: scale(1);
      transition: transform 500ms ease-in;
    }
  
    .card:hover .card__content,
    .card:focus-within .card__content {
      transform: translateY(0);
      transition: transform 500ms ease-in;
    }
  
    .card:focus-within .card__content {
      transition-duration: 0ms;
    }
  
    .card:hover .card__background,
    .card:focus-within .card__background {
      transform: scale(1.3);
    }
  
    .card:hover .card__content--container > :not(.card__title),
    .card:hover .card__button,
    .card:focus-within .card__content--container > :not(.card__title),
    .card:focus-within .card__button {
      opacity: 1;
      transition: opacity 500ms ease-in;
      transition-delay: 500ms;
    }
  
    .card:hover .card__title::after,
    .card:focus-within .card__title::after {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: left;
      transition: opacity 500ms ease-in, transform 500ms ease-in;
      transition-delay: 500ms;
    }
  }
  

.initial-letter {
  content: "";
            display: block;
            width: 50px; /* Adjust the size of the circle */
            height: 50px; /* Adjust the size of the circle */
            border-radius: 50%; /* Make it a circle */
            margin: 10px auto; /* Adjust the margin for positioning */
            text-align: center; /* Center the character within the circle */
            line-height: 50px;
}
.service-description {
  color: black;
  position: relative;
  top: 20px;
  left: 15px;
  word-break: normal;
}
.character-circle {
  /* display: inline-block; */
  border: 3px solid #333; /* Border color */
  border-radius: 50%; /* Make it circular */
  width: 40px; /* Adjust the width and height as needed */
  height: 40px;
  text-align: center;
  line-height: 40px; /* Center the text vertically */
  font-size: 18px; /* Adjust the font size */
  font-weight: bold;
}
.service-title h4 {
  position: relative;
  bottom: 38px;
  left: 55px;
  margin: 0 !important;
  font-size: 20px;
  color: #002868 !important;
  width: 250px !important;
}
.service-title p {
  margin: 0 !important;
  color: black;
  position: relative;
  bottom: 15px;
  text-align: justify !important;
  width: 85% !important;
}

.services-subtitle{
  color: #6286bf !important;
  font-size: 15px;
  position: relative;
  bottom: 44px;
  left: 54px;
  margin: 0 !important;
  font-weight: 700;
  width: 250px !important;
}

@media (max-width: 480px) {
  .service-title p {
    width: 75% !important;
    padding-left: 55px !important;
  }
}