.team-name {
    font-weight: bold !important;
    letter-spacing: 1px !important;
    margin-top: 10px !important;
}
.team-frame {
    /* border-bottom: 1px solid #002868; */
    padding-bottom: 20px;
}
.team-role {
    font-size: 14px !important;
    color: gray;
    font-weight: bold !important;
    letter-spacing: 1px;
}
.image_wrapper_blog {
    position: relative;
    height: 500px !important;
    top: 76px;
}
.image-blg {
    width: 45%;
    height: 45vh;
    margin-top: 100px;
    border-radius: 40px;
}
.rrwhite-blg { 
    width: 100%;
    height: 500px !important;
}
.overlay-blg {
    position: absolute; 
    left: 0px;
    color: white;
    width: -webkit-fill-available;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.image_wrapper_blog {
    position: relative !important;
    height: 100% !important;
}
.blog-page-content {
    position: relative;
    top: 46px;
}
.blogs-wrapper {
    margin-bottom: 90px;
}
.close-icon:hover {
    cursor: pointer;
}
.close-icon {
    font-size: 30px !important;
}
.modal-box {
    height: 100%;
}
@media (max-width: 480px) {
    
    .overlay-blg {
        height: 100%;
    }
    .rrwhite-blg, .image_wrapper_blog {
        height: 40vh !important;
        top: 63px;
    }
    .about-us-title {
        font-size: 30px !important;
    }
    .modal-box {
        overflow: scroll;
        display: block;
        height: 100%;
    }
    .blog-card-wrapper-grid {
        padding-left: 0 !important;
        border-bottom: 1px solid lightgrey;
        padding-bottom: 10px;
    }
}