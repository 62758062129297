.btn {
	line-height: 50px !important;
	height: 50px !important;
	text-align: center !important;
	width: 250px !important;
	cursor: pointer !important;
}
.btnbox {
	background-color: black;
}
.btn-three {
	color: #FFF !important;
	transition: all 0.5s !important;
	position: relative !important;
}
.btn-three::before {
	content: '' !important;
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
	z-index: 1 !important;
	background-color: rgba(255,255,255,0.1) !important;
	transition: all 0.3s !important;
}
.btn-three:hover::before {
	opacity: 0  !important;
	transform: scale(0.5,0.5) !important;
}
.btn-three::after {
	content: '' !important;
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	width: 100% !important;
	height: 100% !important;
	z-index: 1 !important;
	opacity: 0 !important;
	transition: all 0.3s !important;
	border: 1px solid rgba(255,255,255,0.5) !important;
	transform: scale(1.2,1.2) !important;
}
.btn-three:hover::after {
	opacity: 1 !important;
	transform: scale(1,1) !important;
}