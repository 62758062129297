.image_wrapper {
    position: relative;
    height: calc(100vh);
  }
.transparent-background {
  background: transparent !important;
}
.home-paper {
  margin-top: 110px;
}
  .image_wrapper-height100vh-home {
    height: 100vh;
  }
  
  .overlay {
    position: absolute; 
    left: 20px;
    top: 80px;
    color: white;
    width: -webkit-fill-available;
  }
.overlayed-image {
    width: 50%;
    float: right;
    opacity: 0.5;
    height: 100%;
    object-fit: cover;
}
.rrwhite { 
  width: 100%;
  /* height: 90%; */
}

.gif{
  position: relative;
    top: -450px;
    opacity: 0.1;
    background-color: rgb(0, 40, 104);
    height: -webkit-fill-available;
}

.home-paper-button {
  width: 200px;
  margin-right: 30px !important;
}

.link {
  color: aliceblue !important;
  margin-right: 10px;
}
.home-carousel-quote {
  padding-left: 4rem;
  padding-top: 4rem;
}
@media (max-width: 480px) {
  .paper-title {
    font-size: 44px !important;
    font-weight: bold !important;
  }
  .paper-subtitle {
    font-size: 20px !important;
  }
  .home-paper-button {
    width: 250px;
    margin-bottom: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .home-carousel-quote {
    padding-left: 0rem;
    padding-top: 0rem;
  }
}